.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-content {
  display: flex;
  min-height: calc(100vh - 80px);
}

.App-sidebar {
  width: 200px;
  background-color: #f0f0f0;
  padding: 20px;
}

.App-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.App-sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
}

.App-main {
  flex-grow: 1;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}
