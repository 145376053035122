@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

body.dark-mode .App-header {
  background-color: #2d2d2d;
}

body.dark-mode .App-sidebar {
  background-color: #2d2d2d;
  color: #ffffff;
}

body.dark-mode a {
  color: #66b3ff;
}

body.dark-mode .settings-container {
  background-color: #2d2d2d;
}

body.dark-mode .settings-container h2,
body.dark-mode .settings-container h3 {
  color: #ffffff;
}

body.dark-mode .form {
  background-color: #333333;
}

* {
  transition: all 0.2s ease-in-out;
}
